import { group } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { firstValueFrom } from 'rxjs';
import { ImpactRiskOpportunity } from 'src/app/models/impact-risk-opportunity';
import { Indicator } from 'src/app/models/indicator';
import { ServerService } from 'src/app/services/server.service';
import { numberScale, riskMatrixColors, YesOrNo } from 'src/app/utils';

@Component({
  selector: 'app-dual-materiality',
  templateUrl: './dual-materiality.page.html',
  styleUrls: ['./dual-materiality.page.scss'],
})
export class DualMaterialityPage implements OnInit {
  @ViewChild('op') overlayPanel!: OverlayPanel; // Referencia al Overlay Panel
  mockIndicator = [{}];
  companyId: any;
  editImpact: boolean = false;
  model: any[] = [];
  loading: boolean = true;
  totales: object = {};
  dialogDualMateriality: boolean = false;
  dmData: any[] = [];
  matrixGroupData: any;
  groupsWithDmData: any[] = [];
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  formImpact: FormGroup;
  active: any = 0;
  loadingModal: boolean = false;
  formDMGroup: FormGroup;
  dialogDualMaterialityForm: boolean = false;
  dialogGroupForm: boolean = false;
  selectedDm: any | null;
  edit: boolean = false;
  dmGroups: any[] = [];
  selectedIndicator: any;
  dialogImpactRisk: boolean = false;
  dialogForm: boolean = false;
  dialogGroup: boolean = false;
  selectedGroup: any | null = null;
  selectedTableGroup: any;
  uploadedFiles: { [step: string]: { [section: string]: File } } = {};
  loadingForm: boolean = false;
  numberScale = numberScale;
  YesOrNo = YesOrNo;
  impacts = [1, 2, 3, 4, 5];
  dialogDeleteDm: boolean = false;
  dialogDeleteGroup: boolean = false;
  financialRelevances = [5, 4, 3, 2, 1];
  loadingModalBtn: boolean = false;
  dialogMatrixClicked: boolean = false;
  indicatorsByDmGroupClicked: any | null = null;
  yesNoOptions = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];
  riskMatrixColors = riskMatrixColors;
  riskRecords: any[] = [];
  stepperIndex: number = 0;
  existingFiles: {
    [step: number]: { [section: number]: { url: string; name: string } };
  } = {};
  keyMapping: { [mainKey: number]: { [subKey: number]: string } } = {
    1: {
      1: 'regulations_document',
      2: 'tendencies_document',
      3: 'expectations_document',
    },
    2: {
      1: 'internal_consultations_document',
      2: 'external_consultations_document',
    },
    3: {
      1: 'impact_document',
      2: 'financial_relevance_document',
    },
  };
  indicators: Indicator[] = [];
  selectedIndicators: Indicator[] = [];
  dialogRiskMatrix: boolean = false;
  impactRiskOportunity: ImpactRiskOpportunity[] = [
    {
      id: 1,
      impact_text: 'Impacto 1',
      impact_doc: null,
      risk_text: 'Riesgo 1',
      risk_doc: null,
      opportunity_text: 'Oportunidad 1',
      opportunity_doc: null,
    },
    {
      id: 2,
      impact_text: 'Impacto 2',
      impact_doc: null,
      risk_text: 'Riesgo 2',
      risk_doc: null,
      opportunity_text: 'Oportunidad 2',
      opportunity_doc: null,
    },
    {
      id: 3,
      impact_text: 'Impacto 3',
      impact_doc: null,
      risk_text: 'Riesgo 3',
      risk_doc: null,
      opportunity_text: 'Oportunidad 3',
      opportunity_doc: null,
    },
    {
      id: 4,
      impact_text: 'Impacto 4',
      impact_doc: null,
      risk_text: 'Riesgo 4',
      risk_doc: null,
      opportunity_text: 'Oportunidad 4',
      opportunity_doc: null,
    },
  ];

  upstreamDownstream: any[] = [
    {
      id: 1,
      impact_risk_oportunity: {
        id: 1,
        impact_text: 'Impacto 1',
        impact_doc: null,
        risk_text: 'Riesgo 1',
        risk_doc: null,
        oportunity_text: 'Oportunidad 1',
        oportunity_doc: null,
      },
      magnitude: 1,
      scope: 2,
      irremediability: 4,
      material_impact: true,
    },
    {
      id: 2,
      impact_risk_oportunity: {
        id: 2,
        impact_text: 'Impacto 2',
        impact_doc: null,
        risk_text: 'Riesgo 2',
        risk_doc: null,
        oportunity_text: 'Oportunidad 2',
        oportunity_doc: null,
      },
      magnitude: 2,
      scope: 3,
      irremediability: 3,
      material_impact: false,
    },
    {
      id: 3,
      impact_risk_oportunity: {
        id: 3,
        impact_text: 'Impacto 3',
        impact_doc: null,
        risk_text: 'Riesgo 3',
        risk_doc: null,
        oportunity_text: 'Oportunidad 3',
        oportunity_doc: null,
      },
      magnitude: 3,
      scope: 1,
      irremediability: 2,
      material_impact: true,
    },
    {
      id: 4,
      impact_risk_oportunity: {
        id: 4,
        impact_text: 'Impacto 4',
        impact_doc: null,
        risk_text: 'Riesgo 4',
        risk_doc: null,
        oportunity_text: 'Oportunidad 4',
        oportunity_doc: null,
      },
      magnitude: 4,
      scope: 4,
      irremediability: 1,
      material_impact: false,
    },
  ];
  selectedItemStep5: any; // Elemento seleccionado
  selectedFieldStep5: string = ''; // Campo seleccionado
  selectedIndexStep5: number = -1; // Índice del elemento seleccionado
  financialMateriality: any[] = [
    {
      id: 1,
      impact_risk_oportunity: {
        id: 1,
        impact_text: 'Impacto 1',
        impact_doc: null,
        risk_text: 'Riesgo 1',
        risk_doc: null,
        oportunity_text: 'Oportunidad 1',
        oportunity_doc: null,
      },
      magnitude: 1,
      scope: 2,
      irremediability: 4,
      material_impact: true,
    },
    {
      id: 2,
      impact_risk_oportunity: {
        id: 2,
        impact_text: 'Impacto 2',
        impact_doc: null,
        risk_text: 'Riesgo 2',
        risk_doc: null,
        oportunity_text: 'Oportunidad 2',
        oportunity_doc: null,
      },
      magnitude: 2,
      scope: 3,
      irremediability: 3,
      material_impact: false,
    },
    {
      id: 3,
      impact_risk_oportunity: {
        id: 3,
        impact_text: 'Impacto 3',
        impact_doc: null,
        risk_text: 'Riesgo 3',
        risk_doc: null,
        oportunity_text: 'Oportunidad 3',
        oportunity_doc: null,
      },
      magnitude: 3,
      scope: 1,
      irremediability: 2,
      material_impact: true,
    },
    {
      id: 4,
      impact_risk_oportunity: {
        id: 4,
        impact_text: 'Impacto 4',
        impact_doc: null,
        risk_text: 'Riesgo 4',
        risk_doc: null,
        oportunity_text: 'Oportunidad 4',
        oportunity_doc: null,
      },
      magnitude: 4,
      scope: 4,
      irremediability: 1,
      material_impact: false,
    },
  ];
  selectedItemStep6: any; // Elemento seleccionado
  selectedFieldStep6: string = ''; // Campo seleccionado
  selectedIndexStep6: number = -1; // Índice del elemento seleccionado
  dynamicFormArrayStep7: any[] = [];
  formLabel: string = '';
  selectedImpactRegister: ImpactRiskOpportunity | null;

  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getIndicators();

    this.form1 = this.fb.group({
      regulations_text: [''],
      business_text: [''],
      understanding_stakeholders_text: [''],
    });

    this.form2 = this.fb.group({
      internal_consultations_text: [''],
      external_consultations_text: [''],
    });

    this.form3 = this.fb.group({
      impact: [''],
      impact_text: [''],
      financial_relevance: [''],
      financial_relevance_text: [''],
    });

    this.form4 = this.fb.group({
      up_down_text: [''],
      lca_text: [''],
    });

    this.form5 = this.fb.group({
      transparency_text: [''],
      accordance_text: [''],
    });

    this.formDMGroup = this.fb.group({
      name: ['', Validators.required],
    });

    this.formImpact = this.fb.group({
      impact_text: [''],
      risk_text: [''],
      opportunity_text: [''],
    });

    this.companyId = sessionStorage.getItem('companyId');
    this.getDualMaterialitiesByGroup();
    this.getDMGroups();
    // this.recuperarDatosMenu();
  }

  /**
   * Control de estilo de celdas de tabla
   * @param value
   * @returns
   */
  getColorClass(value: number): string {
    switch (value) {
      case 1:
        return 'low';
      case 2:
        return 'medium-low';
      case 3:
        return 'medium';
      case 4:
        return 'medium-high';
      case 5:
        return 'high';
      default:
        return '';
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 5
   * @param event
   * @param overlayPanel
   */
  openOverlayStep5(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep5 = field;
    this.selectedItemStep5 = item;
    this.selectedIndexStep5 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Usamos la referencia del ViewChild
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 6
   * @param event
   * @param overlayPanel
   */
  openOverlayStep6(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep6 = field;
    this.selectedItemStep6 = item;
    this.selectedIndexStep6 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Usamos la referencia del ViewChild
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Actualizar localmente datos de la tabla paso 5
   * @param rowIndex
   * @param field
   * @param newValue
   */
  updateValueStep5(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    this.upstreamDownstream[index][field] = value;
    overlayPanel.hide();
  }

  /**
   * Actualizar localmente datos de la tabla paso 6
   * @param rowIndex
   * @param field
   * @param newValue
   */
  updateValueStep6(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    this.financialMateriality[index][field] = value;
    overlayPanel.hide();
  }

  /**
   * Obtener listado de indicadores
   */
  getIndicators() {
    this.serverService
      .getData('/api/getIndicatorsForDualMateriality/')
      .subscribe({
        next: (response) => {
          this.indicators = response.data;
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
        },
      });
  }

  /**
   * Obtener registros de doble materialidad por grupos (estos datos se pintarán en la matriz de riesgos)
   */
  getDualMaterialitiesByGroup() {
    this.serverService.getData(`/api/dual-materialities/byGroup`).subscribe({
      next: (response) => {
        if (response.data) {
          // this.groupsWithDmData = response.data;
          // console.log(this.groupsWithDmData);

          // Mock
          this.groupsWithDmData.push({
            id: 2,
            name: 'Materialidad 2024 - UMBRELLA GLOBAL ENERGY',
            company_id: 1,
            created_at: '2024-11-14T10:11:48.000000Z',
            dual_materiality: [
            //   {
            //     created_at: '2024-11-15T07:51:37.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-15T07:51:37.000000Z',
            //       dual_materiality_id: 12,
            //       financial_relevance: '4',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: 'test',
            //       id: 2,
            //       impact: '2',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: 'test',
            //       updated_at: '2024-11-15T07:51:37.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 12,
            //     indicator: {
            //       created_at: null,
            //       description: null,
            //       disabled: 0,
            //       id: 1196,
            //       indicator_configuration_id: 10,
            //       name: 'Basis for preparation of sustainability statement',
            //       updated_at: '2024-06-20T12:16:59.000000Z',
            //     },
            //     indicator_id: 1196,
            //     updated_at: '2024-11-15T07:51:37.000000Z',
            //   },
            //   {
            //     created_at: '2024-11-15T07:51:37.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-15T07:51:37.000000Z',
            //       dual_materiality_id: 13,
            //       financial_relevance: '4',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: 'test',
            //       id: 3,
            //       impact: '2',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: 'test',
            //       updated_at: '2024-11-15T07:51:37.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 13,
            //     indicator: {
            //       created_at: null,
            //       description: null,
            //       disabled: 0,
            //       id: 1197,
            //       indicator_configuration_id: 11,
            //       name: 'Scope of consolidation of consolidated sustainability statement is same as for financial statements',
            //       updated_at: '2024-06-20T12:22:30.000000Z',
            //     },
            //     indicator_id: 1197,
            //     updated_at: '2024-11-15T07:51:37.000000Z',
            //   },
            //   {
            //     created_at: '2024-11-15T07:51:37.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-15T07:51:37.000000Z',
            //       dual_materiality_id: 14,
            //       financial_relevance: '4',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: 'test',
            //       id: 4,
            //       impact: '2',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: 'test',
            //       updated_at: '2024-11-15T07:51:37.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 14,
            //     indicator: {
            //       created_at: null,
            //       description: null,
            //       disabled: 0,
            //       id: 1199,
            //       indicator_configuration_id: 13,
            //       name: 'Disclosure of extent to which sustainability statement covers upstream and downstream value chain ',
            //       updated_at: '2024-06-20T12:24:57.000000Z',
            //     },
            //     indicator_id: 1199,
            //     updated_at: '2024-11-15T07:51:37.000000Z',
            //   },
            //   {
            //     created_at: '2024-11-15T07:51:37.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-15T07:51:37.000000Z',
            //       dual_materiality_id: 15,
            //       financial_relevance: '4',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: 'test',
            //       id: 5,
            //       impact: '2',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: 'test',
            //       updated_at: '2024-11-15T07:51:37.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 15,
            //     indicator: {
            //       created_at: null,
            //       description: null,
            //       disabled: 0,
            //       id: 1205,
            //       indicator_configuration_id: 185,
            //       name: 'Disclosure of metrics that include value chain data estimated using indirect sources ',
            //       updated_at: '2024-11-13T16:38:00.000000Z',
            //     },
            //     indicator_id: 1205,
            //     updated_at: '2024-11-15T07:51:37.000000Z',
            //   },
            //   {
            //     created_at: '2024-11-15T08:26:00.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-15T08:26:00.000000Z',
            //       dual_materiality_id: 16,
            //       financial_relevance: '2',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: null,
            //       id: 6,
            //       impact: '4',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: null,
            //       updated_at: '2024-11-15T08:26:00.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 16,
            //     indicator: {
            //       created_at: '2024-08-18T16:41:13.000000Z',
            //       description: null,
            //       disabled: 0,
            //       id: 1234,
            //       indicator_configuration_id: null,
            //       name: 'S1.MDR-T_01 Relación con los objetivos de la política',
            //       updated_at: '2024-10-14T09:26:48.000000Z',
            //     },
            //     indicator_id: 1234,
            //     updated_at: '2024-11-15T08:26:00.000000Z',
            //   },
            //   {
            //     created_at: '2024-11-18T08:29:48.000000Z',
            //     dual_materiality1: null,
            //     dual_materiality2: null,
            //     dual_materiality3: {
            //       created_at: '2024-11-18T08:29:48.000000Z',
            //       dual_materiality_id: 17,
            //       financial_relevance: '5',
            //       financial_relevance_document: null,
            //       financial_relevance_document_hash: null,
            //       financial_relevance_text: null,
            //       id: 7,
            //       impact: '1',
            //       impact_document: null,
            //       impact_document_hash: null,
            //       impact_text: null,
            //       updated_at: '2024-11-18T08:29:48.000000Z',
            //     },
            //     dual_materiality4: null,
            //     dual_materiality5: null,
            //     dual_materiality_group_id: 2,
            //     id: 17,
            //     indicator: {
            //       created_at: '2024-08-18T16:35:14.000000Z',
            //       description: null,
            //       disabled: 0,
            //       id: 1227,
            //       indicator_configuration_id: null,
            //       name: 'S1.MDR-A_10 Recursos financieros actuales asignados al plan de acción (Opex)',
            //       updated_at: '2024-10-14T09:19:45.000000Z',
            //     },
            //     indicator_id: 1227,
            //     updated_at: '2024-11-18T08:29:48.000000Z',
            //   },
            ],
            matrixValues: [
              {
                count: 1,
                financial_relevance_avg: 4,
                financial_relevance_total: 4,
                impact_avg: 2,
                impact_total: 2,
                parent_icon: 'pi pi-globe',
                parent_id: 17,
                parent_label: 'ESRS 2 - General Disclouses',
                indicators: [1205],
                indicatorsData: [
                  {
                    created_at: null,
                    description: null,
                    disabled: 0,
                    id: 1205,
                    indicator_configuration_id: 185,
                    name: 'Basis for preparation of sustainability statement',
                    updated_at: '2024-11-13T16:38:00.000000Z',
                  },
                  {
                    created_at: null,
                    description: null,
                    disabled: 0,
                    id: 1205,
                    indicator_configuration_id: 185,
                    name: 'Scope of consolidation of consolidated sustainability statement is same as for financial statements',
                    updated_at: '2024-11-13T16:38:00.000000Z',
                  },
                  {
                    created_at: null,
                    description: null,
                    disabled: 0,
                    id: 1205,
                    indicator_configuration_id: 185,
                    name: 'Disclosure of extent to which sustainability statement covers upstream and downstream value chain',
                    updated_at: '2024-11-13T16:38:00.000000Z',
                  },
                  {
                    created_at: null,
                    description: null,
                    disabled: 0,
                    id: 1205,
                    indicator_configuration_id: 185,
                    name: 'Disclosure of metrics that include value chain data estimated using indirect sources',
                    updated_at: '2024-11-13T16:38:00.000000Z',
                  },
                ],
              },
              {
                count: 2,
                financial_relevance_avg: 3.5,
                financial_relevance_total: 7,
                impact_avg: 2.5,
                impact_total: 5,
                parent_icon: 'pi pi-users',
                parent_id: '1260391a-638e-4c7b-8865-da279fb86955',
                parent_label: 'ESRS S1 - Trabajadores Propios',
                indicators: [1234, 1227],
                indicatorsData: [
                  {
                    created_at: '2024-08-18T16:41:13.000000Z',
                    description: null,
                    disabled: 0,
                    id: 1234,
                    indicator_configuration_id: null,
                    name: 'S1.MDR-T_01 Relación con los objetivos de la política',
                    updated_at: '2024-10-14T09:26:48.000000Z',
                  },
                  {
                    created_at: '2024-08-18T16:35:14.000000Z',
                    description: null,
                    disabled: 0,
                    id: 1227,
                    indicator_configuration_id: null,
                    name: 'S1.MDR-A_10 Recursos financieros actuales asignados al plan de acción (Opex)',
                    updated_at: '2024-10-14T09:19:45.000000Z',
                  },
                ],
              },
              {
                financial_relevance_avg: 0,
                impact_avg: 0,
                parent_id: null,
                parent_label: 'No Encontrados',
                indicators: [1196, 1197, 1199],
              },
            ],
          });
        }
      },
      error: (err) => {
        this.loading = false;
        console.error(
          'Error al obtener los registros de doble materialidad por grupos',
          err
        );
      },
    });
  }

  /**
   * Abrir modal de matriz de riesgos
   */
  openRiskMatrixDialog(group: any) {
    this.dialogRiskMatrix = true;
    this.getRiskMatrixData(group);
  }

  /**
   * Definir estilos en matriz de riesgos
   * @param rowIndex
   * @param colIndex
   * @returns
   */
  getCellClass(rowIndex: number, colIndex: number): string {
    return this.riskMatrixColors[rowIndex][colIndex];
  }

  /**
   * Función que filtra los registros para devolver solo aquellos que coinciden con la frecuencia e impacto dados e imprimirlos en la celda actual
   * @param frequency
   * @param impact
   * @returns
   */
  getRecordsForCell(frequency: string, impact: string): any[] {
    return this.riskRecords.filter(
      (record) => record.frequency === frequency && record.impact === impact
    );
  }

  /**
   * Manejar pulsación de botón para nuevo grupo o doble materialidad para multiples indicadores
   * @param event
   */
  handleAddButton(event) {
    // this.dialogForm = true;
    this.dialogGroup = true;
    this.dialogForm = false;
  }

  /**
   * Manejador de selección de tipo de formulario
   */
  formSelected(type: number) {
    if (type === 1) {
    }

    if (type === 2) {
      this.dialogGroup = true;
      this.dialogForm = false;
    }
  }

  /**
   * Mostrar modal de creación/edición de grupo
   */
  showDialog(group?: any) {
    if (group) {
      this.selectedTableGroup = group;
      this.formDMGroup.patchValue(group);
    }

    this.edit = group ? true : false;
    this.dialogGroup = false;
    this.dialogGroupForm = true;
  }

  /**
   * Obtener grupos de doble materialidad
   */
  async getDMGroups() {
    try {
      const response = await firstValueFrom(
        this.serverService.getData(`/api/dual-materiality/groups`)
      );
      this.dmGroups = response.data ? response.data : [];
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.error('Error al obtener el listado de grupos', err);
    }
  }

  /**
   * Generador de datos en las celdas para un grupo
   */
  getRiskMatrixData(group: any): void {
    this.matrixGroupData = group;

    // Procesamos grupo seleccionado
    this.matrixGroupData.matrixData = {};

    this.matrixGroupData['matrixValues'].forEach((item) => {
      // Verificar si el parent_id está definido
      if (item.parent_id) {
        // Obtener las puntuaciones
        const financialRelevance = Math.floor(item.financial_relevance_avg);
        const impact = Math.floor(item.impact_avg);

        // Validar que las puntuaciones estén dentro del rango
        if (
          financialRelevance >= 1 &&
          financialRelevance <= 5 &&
          impact >= 1 &&
          impact <= 5
        ) {
          // Si la celda aún no existe, inicializarla
          if (!this.matrixGroupData.matrixData[financialRelevance]) {
            this.matrixGroupData.matrixData[financialRelevance] = {};
          }

          if (!this.matrixGroupData.matrixData[financialRelevance][impact]) {
            this.matrixGroupData.matrixData[financialRelevance][impact] = [];
          }

          // Agregar el registro a la celda correspondiente
          this.matrixGroupData.matrixData[financialRelevance][impact].push(
            item
          );
        }
      }
    });
    console.log(this.matrixGroupData);
  }


  /**
   * Manejar modal para mostrar indicadores de un p-chip pulsado
   */
  showIndicatorsByChip(items) {
    console.log(items);
    this.indicatorsByDmGroupClicked = items;
    this.dialogMatrixClicked = true;
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    this.serverService
      .getData('/api/menusWithIndicatorName/' + this.companyId)
      .subscribe({
        next: (response) => {
          this.model = response.data
            ? response.data['dual_materiality'].map((record) => {
                // Llamamos a la función que verifica si todos los formularios están completos
                const isComplete = this.checkDualMaterialityCompletion(record);

                // Retornamos el registro original con un nuevo campo "isComplete"
                return {
                  ...record,
                  isComplete: isComplete, // Añadimos el campo 'isComplete'
                };
              })
            : [];
          this.loading = true;
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
          this.loading = true;
        },
      });
  }

  /**
   * Validador de formularios completos por separado
   * @param record
   * @returns
   */
  checkDualMaterialityCompletion(record: any): boolean {
    const materialityKeys = [
      'dual_materiality1',
      'dual_materiality2',
      'dual_materiality3',
      'dual_materiality4',
      'dual_materiality5',
    ];

    // Recorremos cada objeto de dual materiality
    for (const key of materialityKeys) {
      const materiality = record[key];
      if (materiality) {
        for (const field in materiality) {
          if (
            !field.includes('hash') &&
            field !== 'created_at' &&
            field !== 'updated_at' &&
            (materiality[field] === null || materiality[field] === '')
          ) {
            return false; // Si algún campo no está cumplimentado, devolvemos false
          }
        }
      }
    }
    return true; // Si todos los campos están cumplimentados, devolvemos true
  }

  /**
   * Manejador de cierre de modal de doble materialidad
   */
  handleHideDualMateriality() {
    this.selectedGroup = null;
    this.selectedIndicators = [];
    this.form1.reset();
    this.form2.reset();
    this.form3.reset();
    this.form4.reset();
    this.form5.reset();

    // Reinicia el índice del stepper
    this.stepperIndex = 0;
  }

  /**
   * Enviar formulario de doble materialidad
   */
  onSubmitForm() {
    if (!this.selectedGroup) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar un grupo de doble materialidad',
      });
    }

    if (!this.edit && this.selectedIndicators.length === 0) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar al menos un indicador',
      });
    }

    const formData = new FormData();
    const allFormData = {
      ...this.form1.value,
      ...this.form2.value,
      ...this.form3.value,
      ...this.form4.value,
      ...this.form5.value,
    };

    // Procesar datos del formulario excluyendo los vacíos
    Object.keys(allFormData).forEach((key) => {
      const value = allFormData[key];
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });

    // Procesar los documentos y mapear las claves
    Object.keys(this.uploadedFiles).forEach((mainKey) => {
      Object.keys(this.uploadedFiles[mainKey]).forEach((subKey) => {
        const file = this.uploadedFiles[mainKey][subKey];
        const key = this.keyMapping[Number(mainKey)]?.[Number(subKey)];
        if (key && file) {
          formData.append(key, file);
        }
      });
    });

    formData.append('dual_materiality_group_id', this.selectedGroup.id);
    this.loadingForm = true;

    const request = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materialities/dual-materiality/${this.selectedDm.id}`,
          formData
        )
      : this.serverService.sendData(
          `/api/dual-materialities/${JSON.stringify(this.selectedIndicators)}`,
          formData
        );

    request.subscribe({
      next: (response) => {
        if (response.data) {
          this.dialogDualMaterialityForm = false;
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Registro de doble materialidad guardado correctamente',
          });
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error(
          'Error al guardar el registro de doble materialidad',
          err
        );
        if (err.status === 409) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ya hay un registro de doble materialidad con este grupo asignado, inténtelo con otro diferente',
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Error al guardar el registro de doble materialidad, inténtelo de nuevo',
          });
        }
        this.loadingForm = false;
      },
    });
  }

  /**
   * Enviar formulario de grupo de doble materialidad
   */
  async onSubmitGroupForm() {
    this.formDMGroup.markAllAsTouched();

    if (this.formDMGroup.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise el formulario',
      });
    }

    const formData = new FormData();
    const formValue = this.formDMGroup.value;

    // Nombre duplicado
    const isDuplicated = this.dmGroups.some(
      (group) =>
        group.name === formValue.name && this.companyId === group.company_id
    );

    if (isDuplicated) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Ya existe un grupo con el mismo nombre',
      });
    }

    formData.append('name', formValue.name);
    formData.append('company_id', this.companyId);

    this.loadingForm = true;

    const requestObservable = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materiality/groups/${this.selectedTableGroup.id}`,
          formData
        )
      : this.serverService.sendData(`/api/dual-materiality/groups`, formData);

    const successDetail = this.edit
      ? 'El grupo de doble materialidad se ha actualizado correctamente'
      : 'El grupo de doble materialidad se ha creado correctamente';

    requestObservable.subscribe({
      next: (response) => {
        if (response.data) {
          this.getDMGroups();
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: successDetail,
          });
          this.formDMGroup.reset();
          this.dialogGroupForm = false;
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error('Error al enviar formulario', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Ocurrió un error registrando el grupo, inténtelo de nuevo',
        });
        this.loadingForm = false;
      },
    });
  }

  /**
   * Observador de archivos para el componente p-fileUpload
   */
  getUploadedFiles(step: number, section: number): File[] {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      return [this.uploadedFiles[step][section]];
    }
    return [];
  }

  /**
   * Manejador de subida de archivo
   * @param event
   */
  onFileSelect(event: any, step: number, section: number): void {
    const selectedFiles = event.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (!this.uploadedFiles[step]) {
        this.uploadedFiles[step] = {};
      }
      this.uploadedFiles[step][section] = selectedFile;
    }
  }

  /**
   * Manejar eliminación de archivo
   * @param event
   */
  deleteFileSelected(event: any, step: number, section: number): void {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      delete this.uploadedFiles[step][section];
    }
  }

  /**
   * Eliminar un grupo de doble materialidad
   */
  deleteGroup(group) {
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.dialogDeleteGroup = true;
    }
  }

  /**
   * Mostrar modal de formulario de creación/edición de registro de doble materialidad
   */
  async showDualMaterialityFormDialog(group: any, dm?: any) {
    this.selectedGroup = group;

    if (dm) {
      this.selectedDm = dm;
      console.log(dm);
      // Rellenar los formularios con los datos existentes
      if (dm.dual_materiality1) {
        this.form1.patchValue({
          regulations_text: dm.dual_materiality1.regulations_text,
          tendencies_text: dm.dual_materiality1.tendencies_text,
          expectations_text: dm.dual_materiality1.expectations_text,
        });
      }

      if (dm.dual_materiality2) {
        this.form2.patchValue({
          internal_consultations_text:
            dm.dual_materiality2.internal_consultations_text,
          external_consultations_text:
            dm.dual_materiality2.external_consultations_text,
        });
      }

      if (dm.dual_materiality3) {
        this.form3.patchValue({
          impact: +dm.dual_materiality3.impact,
          impact_text: dm.dual_materiality3.impact_text,
          financial_relevance: +dm.dual_materiality3.financial_relevance,
          financial_relevance_text:
            dm.dual_materiality3.financial_relevance_text,
        });
      }

      if (dm.dual_materiality4) {
        this.form4.patchValue({
          up_down_text: dm.dual_materiality4.up_down_text,
          lca_text: dm.dual_materiality4.lca_text,
        });
      }

      if (dm.dual_materiality5) {
        this.form5.patchValue({
          transparency_text: dm.dual_materiality5.transparency_text,
          accordance_text: dm.dual_materiality5.accordance_text,
        });
      }

      // Manejar los documentos existentes
      this.populateExistingFiles(dm);
    }

    this.edit = dm ? true : false;
    this.dialogDualMaterialityForm = true;
    this.dialogDualMateriality = false;
    this.loadingModal = false;
  }

  /**
   * Función para procesar documentos existentes desde el registro de un indicador
   */
  populateExistingFiles(dm: any): void {
    // Inicializar existingFiles
    this.existingFiles = {};

    // Paso 1
    if (dm.dual_materiality1) {
      const step = 1;
      this.existingFiles[step] = {};

      if (dm.dual_materiality1.regulations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality1.regulations_document,
          name: 'regulations_document.pdf', // Puedes obtener el nombre real si está disponible
        };
      }

      if (dm.dual_materiality1.tendencies_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality1.tendencies_document,
          name: 'tendencies_document.pdf',
        };
      }

      if (dm.dual_materiality1.expectations_document) {
        this.existingFiles[step][3] = {
          url: dm.dual_materiality1.expectations_document,
          name: 'expectations_document.pdf',
        };
      }
    }

    // Paso 2
    if (dm.dual_materiality2) {
      const step = 2;
      this.existingFiles[step] = {};

      if (dm.dual_materiality2.internal_consultations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality2.internal_consultations_document,
          name: 'internal_consultations_document.pdf',
        };
      }

      if (dm.dual_materiality2.external_consultations_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality2.external_consultations_document,
          name: 'external_consultations_document.pdf',
        };
      }
    }

    // Paso 3
    if (dm.dual_materiality3) {
      const step = 3;
      this.existingFiles[step] = {};

      if (dm.dual_materiality3.impact_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality3.impact_document,
          name: 'impact_document.pdf',
        };
      }

      if (dm.dual_materiality3.financial_relevance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality3.financial_relevance_document,
          name: 'financial_relevance_document.pdf',
        };
      }
    }

    // Paso 4
    if (dm.dual_materiality4) {
      const step = 4;
      this.existingFiles[step] = {};

      if (dm.dual_materiality4.up_down_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality4.up_down_document,
          name: 'up_down_document.pdf',
        };
      }

      if (dm.dual_materiality4.lca_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality4.lca_document,
          name: 'lca_document.pdf',
        };
      }
    }

    // Paso 5
    if (dm.dual_materiality5) {
      const step = 5;
      this.existingFiles[step] = {};

      if (dm.dual_materiality5.transparency_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality5.transparency_document,
          name: 'transparency_document.pdf',
        };
      }

      if (dm.dual_materiality5.accordance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality5.accordance_document,
          name: 'accordance_document.pdf',
        };
      }
    }
  }

  /**
   * Eliminar un registro de doble materialidad de un indicador
   */
  deleteDm(dm) {
    this.dialogDeleteDm = true;
    this.selectedDm = dm;
  }

  /**
   * Función auxiliar para selección de eliminación de doble materialidad
   * @param option
   */
  onClickDeleteDm(option: boolean) {
    this.loadingModalBtn = true;
    if (option) {
      this.serverService
        .deleteData(`/api/dual-materialities/${this.selectedDm.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              // Borrado local para actualizar vista de la tabla de DM
              this.dmData = this.dmData.filter(
                (dm) => response.data.id !== dm.id
              );
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail:
                  'Registro de doble materialidad eliminado correctamente',
              });
            }
            this.getDMGroups();
            this.dialogDeleteDm = false;
            this.loadingModalBtn = false;
            this.selectedDm = null;
          },
          error: (err) => {
            console.error('Error al eliminar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el registro de doble materialidad, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteDm = false;
    }
  }

  /**
   * Función auxiliar para selección de eliminación de grupo de doble materialidad
   * @param option
   */
  onClickDeleteGroup(option: boolean) {
    if (option) {
      this.loadingModalBtn = true;
      this.serverService
        .deleteData(`/api/dual-materiality/groups/${this.selectedGroup.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Grupo de doble materialidad eliminado correctamente',
              });
            }
            this.getDMGroups();
            this.dialogDeleteGroup = false;
            this.loadingModalBtn = false;
            this.selectedGroup = null;
          },
          error: (err) => {
            console.error('Error al eliminar el grupo', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el grupo, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteGroup = false;
    }
  }

  /**
   * Abrir documento
   */
  openDocument(document) {
    window.open(document.url, '_blank');
  }

  /**
   * Actualizar campos del formulario de paso 7
   */
  updateStep7FormFields() {
    if (this.formLabel === '') {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe ingresar un título para el campo del formulario',
      });
    }

    const data = {
      label: this.formLabel,
      textarea: null,
    };

    this.dynamicFormArrayStep7.push(data);
    this.formLabel = '';
  }

  /**
   * Eliminar campo del formulario
   */
  deleteFormField(field: any): void {
    const index = this.dynamicFormArrayStep7.indexOf(field); // Obtengo indice del campo seleccionado con indexOf

    if (index > -1) {
      this.dynamicFormArrayStep7.splice(index, 1); // Elimino campo concreto
      this.messageService.add({
        severity: 'success',
        summary: 'Éxito',
        detail: 'Campo eliminado correctamente',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo eliminar el campo',
      });
    }
  }

  /**
   * Mostrar modal de creación/edición de impacto, riesgo y oportunidad
   */
  showImpactRegisterDialog(item?) {
    if (item) {
      this.selectedImpactRegister = item;
      this.formImpact.patchValue(item);
    }
    this.editImpact = item ? true : false;
    this.dialogImpactRisk = true;
  }

  /**
   * Manejar eliminación de producto
   * @param prouct
   */
  deleteImpact(event, item) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        const index = this.impactRiskOportunity.indexOf(item); // Obtengo indice del campo seleccionado con indexOf

        if (index > -1) {
          this.impactRiskOportunity.splice(index, 1); // Elimino campo concreto
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Registro eliminado correctamente',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No se pudo eliminar el registro',
          });
        }
      },
      reject: () => {},
    });
  }

  /**
   * Manejar cierre de modal de impacto
   */
  handleCloseImpactDialog() {
    this.selectedImpactRegister = null;
  }

  downloadRiskMatrixDocument() {
    // URL relativa si el documento está en 'assets' o una URL absoluta si es un endpoint del backend.
    const url = 'assets/docs/Informe_Doble_Materialidad_20 noviembre 2024.docx'; // Ajusta la ruta de acuerdo a la ubicación del archivo.
  
    // Crear un elemento ancla (a) de manera dinámica
    const link = document.createElement('a');
    link.href = url;
  
    // Establecer el atributo de descarga para sugerir un nombre de archivo
    link.download = 'Informe_Doble_Materialidad_20 noviembre 2024.docx'; // Ajusta el nombre según sea necesario
  
    // Añadir el elemento al DOM y hacer clic para iniciar la descarga
    document.body.appendChild(link);
    link.click();
  
    // Limpiar el elemento del DOM
    document.body.removeChild(link);
  }
  
}
